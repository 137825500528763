import { FC, useCallback, useEffect, useRef, useState } from "react";
import YesNoSwitch from "../customSwitch/yesNoSwitch";
import { useSelector, useDispatch } from "react-redux";
import {
  directoryState as ds,
  updateAddUpliftData,
  clearAddUpliftData,
  initializeAddUpliftData,
  clearUpliftData,
  updateEditUpliftData,
  initializeEditUpliftData,
  updateUpliftData as upliftDataUpdate,
} from "../../../../redux/slices/directorySlice";
import AssetStateDropdown from "./customDropdown/assetStateDropdown";
import ConditionsDropdown from "./customDropdown/conditionsDropdown";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete_outlined.svg";
import { ReactComponent as Plus } from "../../../../icons/plus.svg";
import { ReactComponent as PlusWhite } from "../../../../icons/plus_white.svg";
import {
  createUplifts,
  updateUpliftData as editUplift,
} from "../../../../services/uplifts.service";
import { customToast } from "../../../shared-components/custom-toast";
import { useMediaQuery } from "react-responsive";
import TooltipCustom from "./Tooltip";
import { ReactComponent as Star } from "../../../../icons/star.svg";

interface Props {
  type?: any;
  hovered: any;
  setShowdeleteModal: any;
  showModal: any;
  setShowModal: any;
  setReloadData: any;
  reloadData: any;
  setShowCancelModal: any;
  isLoading: boolean;
}

const EditUpliftModal: FC<Props> = ({
  type,
  hovered,
  setShowdeleteModal,
  showModal,
  setShowModal,
  setReloadData,
  reloadData,
  setShowCancelModal,
  isLoading,
}) => {
  const directoryState = useSelector(ds);
  const dispatch = useDispatch();
  const parentRef = useRef(null);
  const modalRef = useRef(null);
  const [marginTopValue, setMarginTopValue] = useState(0);

  useEffect(() => {
    if (parentRef.current && modalRef.current) {
      //@ts-ignore
      if (parentRef.current.clientHeight - modalRef.current.clientHeight < 0) {
        setMarginTopValue(
          //@ts-ignore
          modalRef.current.clientHeight - parentRef.current.clientHeight
        );
      }
    }
  }, [parentRef, modalRef, showModal]);

  useEffect(() => {
    dispatch(clearAddUpliftData());
  }, []);

  const handleSave = () => {
    if (type !== "add") {
      const { id, ...payload } = directoryState.editUpliftData;
      const { fields_name, ...upliftOldData } = directoryState.upliftData;
      editUplift({ ...payload, uplift_id: id, fields_name: fields_name })
        .then(successCallback)
        .catch(errorCallback);
    } else {
      createUplifts(directoryState.addUpliftData)
        .then(successCallback)
        .catch(errorCallback);
    }
  };

  const successCallback = useCallback((res: any) => {
    customToast({ message: res.data.message, type: "success" });
    dispatch(clearUpliftData());
    setReloadData(!reloadData);
    dispatch(upliftDataUpdate(null));
    setShowModal(false);
  }, []);

  const errorCallback = useCallback((error: any) => {
    customToast({
      message: `${error.response.data.detail || "Something went wrong"}`,
      type: "error",
    });
    setShowModal(false);
  }, []);

  const isDisabled = () => {
    if (type !== "add") {
      if (
        directoryState.editUpliftData?.fields_name.length === 0 ||
        !directoryState.editUpliftData?.uplift_name ||
        parseInt(directoryState.editUpliftData?.min_asset_cost) >
          (directoryState.editUpliftData?.max_asset_cost
            ? parseInt(directoryState.editUpliftData?.max_asset_cost)
            : Infinity) ||
        parseInt(directoryState.editUpliftData?.min_asset_age) >
          (directoryState.editUpliftData?.max_asset_age
            ? parseInt(directoryState.editUpliftData?.max_asset_age)
            : Infinity) ||
        parseInt(directoryState.editUpliftData?.min_abn_age) >
          (directoryState.editUpliftData.max_abn_age
            ? parseInt(directoryState.editUpliftData.max_abn_age)
            : Infinity) ||
        parseInt(directoryState.editUpliftData?.min_term) >
          (directoryState.editUpliftData.max_term
            ? parseInt(directoryState.editUpliftData.max_term)
            : Infinity) ||
        parseInt(directoryState.editUpliftData?.min_gst_age) >
          (directoryState.editUpliftData?.max_gst_age
            ? parseInt(directoryState.editUpliftData?.max_gst_age)
            : Infinity) ||
        parseInt(directoryState.editUpliftData?.min_asset_age_plus_term) >
          (directoryState.editUpliftData?.max_asset_age_plus_term
            ? parseInt(directoryState.editUpliftData?.max_asset_age_plus_term)
            : Infinity) || 
        parseInt(directoryState.editUpliftData?.min_credit_score) >
            (directoryState.editUpliftData.max_credit_score
              ? parseInt(directoryState.editUpliftData.max_credit_score)
              : Infinity)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      directoryState.addUpliftData?.fields_name.length === 0 ||
      !directoryState.addUpliftData?.uplift_name ||
      parseInt(directoryState.addUpliftData?.min_asset_cost) >
        (directoryState.addUpliftData?.max_asset_cost
          ? parseInt(directoryState.addUpliftData?.max_asset_cost)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_asset_age) >
        (directoryState.addUpliftData?.max_asset_age
          ? parseInt(directoryState.addUpliftData?.max_asset_age)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_abn_age) >
        (directoryState.addUpliftData.max_abn_age
          ? parseInt(directoryState.addUpliftData.max_abn_age)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_term) >
        (directoryState.addUpliftData.max_term
          ? parseInt(directoryState.addUpliftData.max_term)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_gst_age) >
        (directoryState.addUpliftData?.max_gst_age
          ? parseInt(directoryState.addUpliftData?.max_gst_age)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_asset_age_plus_term) >
        (directoryState.addUpliftData?.max_asset_age_plus_term
          ? parseInt(directoryState.addUpliftData?.max_asset_age_plus_term)
          : Infinity) ||
      parseInt(directoryState.addUpliftData?.min_credit_score) >
          (directoryState.addUpliftData?.max_credit_score
            ? parseInt(directoryState.addUpliftData?.max_credit_score)
            : Infinity)
    ) {
      return true;
    }
    return false;
  };
  const isRed = () => {
    if (type !== "add") {
      if (
        parseInt(directoryState.editUpliftData?.min_asset_cost) >
          parseInt(directoryState.editUpliftData?.max_asset_cost) ||
        parseInt(directoryState.editUpliftData?.min_asset_age) >
          parseInt(directoryState.editUpliftData?.max_asset_age) ||
        parseInt(directoryState.editUpliftData?.min_abn_age) >
          parseInt(directoryState.editUpliftData?.max_abn_age) ||
        parseInt(directoryState.editUpliftData?.min_gst_age) >
          parseInt(directoryState.editUpliftData?.max_gst_age) ||
        parseInt(directoryState.editUpliftData?.min_asset_age_plus_term) >
          parseInt(directoryState.editUpliftData?.max_asset_age_plus_term)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      parseInt(directoryState.addUpliftData?.min_asset_cost) >
        parseInt(directoryState.addUpliftData?.max_asset_cost) ||
      parseInt(directoryState.addUpliftData?.min_asset_age) >
        parseInt(directoryState.addUpliftData?.max_asset_age) ||
      parseInt(directoryState.addUpliftData?.min_abn_age) >
        parseInt(directoryState.addUpliftData?.max_abn_age) ||
      parseInt(directoryState.addUpliftData?.min_gst_age) >
        parseInt(directoryState.addUpliftData?.max_gst_age) ||
      parseInt(directoryState.addUpliftData?.min_asset_age_plus_term) >
        parseInt(directoryState.addUpliftData?.max_asset_age_plus_term)
    ) {
      return true;
    }
    return false;
  };

  const defaultValues = {
    uplift_id: null,
    uplift_name: null,
    property_ownership_status: false,
    asset_state: null,
    min_asset_cost: null,
    max_asset_cost: null,
    min_asset_age: null,
    max_asset_age: null,
    min_abn_age: null,
    max_abn_age: null,
    min_gst_age: null,
    max_gst_age: null,
    min_term: null,
    max_term: null,
    max_asset_age_plus_term: null,
    min_asset_age_plus_term: null,
    private_sale: false,
    sale_hire_back: false,
    brokerage_loading_treatment: null,
    min_credit_score: null,
    max_credit_score: null,
    rate_change: null,
    fields_name: [],
    rate_bracket_id: directoryState.bracketData.id,
    product_id: directoryState.selectedProductId,
  };
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1170px)" });
  return (
    <>
      {type === "add" ? (
        <button
          className={`mr-[2%] border-2 border-opacity-2 ${
            hovered ? "border-[#6457a4]" : "border-[#c9a24a]"
          } rounded-md cursor-pointer p-[0.8%]`}
          type="button"
          onClick={() => {
            if (!isLoading) {
              setShowModal(true);
              dispatch(initializeAddUpliftData(defaultValues));
            }
          }}
        >
          {hovered ? <PlusWhite /> : <Plus />}
        </button>
      ) : (
        <div
          className={`border-2 ${
            hovered
              ? "border-[#6457a4] text-white"
              : "border-[#c9a24a] text-black"
          } font-[700] px-4 py-2 rounded-md cursor-pointer`}
          onClick={() => {
            if (!isLoading) {
              setShowModal(true);
              dispatch(initializeEditUpliftData(directoryState.upliftData));
            }
          }}
        >
          Edit
        </div>
      )}
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden text-black overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            ref={parentRef}
          >
            <div
              className="relative w-auto my-6 max-w-fit"
              ref={modalRef}
              style={{ marginTop: `${marginTopValue + 60}px` }}
            >
              <div className="border-0 rounded-lg shadow-lg px-4 py-5 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between px-8 rounded-t">
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row mt-[3%]"
                    } justify-between w-full items-center ml-[1.5%]`}
                  >
                    <div className=" text-4xl font-semibold font-grotesk">
                      {type === "add" ? "Add" : "Edit"} Uplift
                    </div>
                    <div className="flex flex-row">
                      {type !== "add" && (
                        <button
                          className="border-2 border-[#cccccc] px-4 rounded-md py-2 font-bold text-base mx-2"
                          type="button"
                          onClick={() => {
                            setShowdeleteModal(true);
                            setShowModal(false);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      <button
                        className=" border-2 border-[#cccccc] px-4 rounded-md py-2 font-bold text-base mx-2"
                        onClick={() => {
                          if (type !== "add") {
                            setShowCancelModal(true);
                          }
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          isDisabled() ? "bg-[#c5c0dd]" : "bg-[#3e2d8d]"
                        } text-white font-bold px-6 py-2 rounded-md mx-5 text-base`}
                        onClick={() =>
                          !isDisabled() ? handleSave() : () => {}
                        }
                        disabled={isDisabled()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {isRed() && (
                  <div className="flex flex-row justify-end mr-8 text-[#f74343]">
                    To continue fix the fields marked with red
                  </div>
                )}
                <div
                  className={`relative ${
                    !isSmallScreen && "p-4 pl-10"
                  } flex-auto`}
                >
                  {!isSmallScreen && (
                    <div
                      className={`border-b border-solid border-gray-200 mx-2 mr-9`}
                    ></div>
                  )}
                  <div className="mx-2 mt-[2%] opacity-90 flex flex-row">
                    Name <Star className="mx-1" />
                  </div>
                  <input
                    placeholder="Enter"
                    className={`bg-[#f2f2f2] p-3 font-bold ${
                      !isSmallScreen && "w-[94%]"
                    } mx-2 rounded-md placeholder:font-normal`}
                    value={
                      type === "add"
                        ? directoryState.addUpliftData?.uplift_name || ""
                        : directoryState.editUpliftData?.uplift_name || ""
                    }
                    onChange={(e) =>
                      dispatch(
                        type === "add"
                          ? updateAddUpliftData({ uplift_name: e.target.value })
                          : updateEditUpliftData({
                              uplift_name: e.target.value,
                            })
                      )
                    }
                  />
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    } mt-1`}
                  >
                    <div
                      className={`flex flex-col ${
                        isSmallScreen ? "" : "w-1/3"
                      } mr-[3%]`}
                    >
                      <div className="mx-2 mt-2 opacity-90 flex flex-row">
                        Rate Change <Star className="mx-1" />
                      </div>
                      <div className="flex flex-row">
                        <div
                          className={`flex flex-row items-center rounded-md p-3 mx-2 ${
                            !isSmallScreen && "w-full"
                          } bg-[#f2f2f2]`}
                        >
                          <div className="opacity-70">%</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className={`bg-[#f2f2f2] font-bold w-[90%] ${
                              !isSmallScreen && "mx-2"
                            } border-0 p-0 placeholder:font-normal`}
                            value={
                              type === "add"
                                ? directoryState.addUpliftData?.rate_change ||
                                  ""
                                : directoryState.editUpliftData?.rate_change ||
                                  ""
                            }
                            onChange={(e) =>
                              dispatch(
                                type === "add"
                                  ? updateAddUpliftData({
                                      rate_change: e.target.value,
                                    })
                                  : updateEditUpliftData({
                                      rate_change: e.target.value,
                                    })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-2/3">
                      <div className="mx-2 mt-2 opacity-90 flex flex-row">
                        Conditions <Star className="mx-1" />
                      </div>
                      {isSmallScreen ? (
                        <div className="mx-2">
                          <ConditionsDropdown type={type} />
                        </div>
                      ) : (
                        <ConditionsDropdown type={type} />
                      )}
                    </div>
                  </div>
                  <div className="my-5">
                    <div
                      className={`border-b border-solid border-gray-200 mx-2 mr-9`}
                    ></div>
                  </div>
                  <div
                    className={`flex ${
                      isSmallScreen ? "flex-col" : "flex-row"
                    }`}
                  >
                    <div className="flex flex-col">
                      <div className="mx-2 opacity-90">Asset State</div>
                      <div className="flex flex-row items-center mx-2 ">
                        <AssetStateDropdown type={type} />
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">Asset age</div>
                        <div className={`flex flex-row`}>
                          <div className="border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full">
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.min_asset_age || ""
                                  : directoryState.editUpliftData
                                      ?.min_asset_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_asset_age:
                                           e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_asset_age:
                                          e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_age
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData?.min_asset_age
                                ) >
                                parseInt(
                                  directoryState.editUpliftData?.max_asset_age
                                )
                              : parseInt(
                                  directoryState.addUpliftData?.min_asset_age
                                ) >
                                parseInt(
                                  directoryState.addUpliftData?.max_asset_age
                                )) && (
                              <div className="absolute top-[calc(-4rem)] ml-[-5%] text-center bg-[#f74343] text-white text-xs px-2 py-1 rounded shadow">
                                The maxmum value can't be{" "}
                                <span className="font-bold">smaller</span> than
                                the minimum.
                                <svg
                                  className="absolute text-[#f74343] h-2 w-full left-0 top-full"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 255 255"
                                >
                                  <polygon
                                    className="fill-current"
                                    points="0,0 127.5,127.5 255,0"
                                  />
                                </svg>
                              </div>
                            )}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_age
                                    )) && "text-[#f74343]"
                              } font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.max_asset_age || ""
                                  : directoryState.editUpliftData
                                      ?.max_asset_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_asset_age: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_asset_age: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">GST age</div>
                        <div className="flex flex-row">
                          <div className="border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full">
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.min_gst_age ||
                                    ""
                                  : directoryState.editUpliftData
                                      ?.min_gst_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_gst_age: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_gst_age: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_gst_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_gst_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_gst_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_gst_age
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData?.min_gst_age
                                ) >
                                parseInt(
                                  directoryState.editUpliftData?.max_gst_age
                                )
                              : parseInt(
                                  directoryState.addUpliftData?.min_gst_age
                                ) >
                                parseInt(
                                  directoryState.addUpliftData?.max_gst_age
                                )) && (
                              <div className="absolute top-[calc(-4rem)] ml-[-5%] text-center bg-[#f74343] text-white text-xs px-2 py-1 rounded shadow">
                                The maxmum value can't be{" "}
                                <span className="font-bold">smaller</span> than
                                the minimum.
                                <svg
                                  className="absolute text-[#f74343] h-2 w-full left-0 top-full"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 255 255"
                                >
                                  <polygon
                                    className="fill-current"
                                    points="0,0 127.5,127.5 255,0"
                                  />
                                </svg>
                              </div>
                            )}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_gst_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_gst_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_gst_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_gst_age
                                    )) && "text-[#f74343]"
                              } font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.max_gst_age ||
                                    ""
                                  : directoryState.editUpliftData
                                      ?.max_gst_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_gst_age: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_gst_age: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">Asset Cost</div>
                        <div className="flex flex-row">
                          <div
                            className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">$</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.min_asset_cost || ""
                                  : directoryState.editUpliftData
                                      ?.min_asset_cost || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_asset_cost:
                                          e.target.value === ""
                                            ? 0
                                            : e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_asset_cost:
                                          e.target.value === ""
                                            ? 0
                                            : e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_cost
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_cost
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_cost
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_cost
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData?.min_asset_cost
                                ) >
                                parseInt(
                                  directoryState.editUpliftData?.max_asset_cost
                                )
                              : parseInt(
                                  directoryState.addUpliftData?.min_asset_cost
                                ) >
                                parseInt(
                                  directoryState.addUpliftData?.max_asset_cost
                                )) && <TooltipCustom />}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">$</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_cost
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_cost
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_cost
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_cost
                                    )) && "text-[#f74343]"
                              } font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.max_asset_cost || ""
                                  : directoryState.editUpliftData
                                      ?.max_asset_cost || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_asset_cost:
                                          e.target.value === ""
                                            ? 0
                                            : e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_asset_cost:
                                          e.target.value === ""
                                            ? 0
                                            : e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mx-2 mt-3 opacity-90">Credit Score</div>
                      <div className="flex flex-row">
                        <div
                          className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                        >
                          <div className="mx-1 opacity-70">Min</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                            value={
                              type === "add"
                                ? directoryState.addUpliftData
                                    ?.min_credit_score || ""
                                : directoryState.editUpliftData
                                    ?.min_credit_score || ""
                            }
                            onChange={(e) =>
                              dispatch(
                                type === "add"
                                  ? updateAddUpliftData({
                                      min_credit_score: e.target.value,
                                    })
                                  : updateEditUpliftData({
                                      min_credit_score: e.target.value,
                                    })
                              )
                            }
                          />
                        </div>
                        <div
                          className={`${
                            (
                              type !== "add"
                                ? parseInt(
                                    directoryState.editUpliftData
                                      ?.min_credit_score
                                  ) >
                                  parseInt(
                                    directoryState.editUpliftData
                                      ?.max_credit_score
                                  )
                                : parseInt(
                                    directoryState.addUpliftData
                                      ?.min_credit_score
                                  ) >
                                  parseInt(
                                    directoryState.addUpliftData
                                      ?.max_credit_score
                                  )
                            )
                              ? "border-[#f74343]"
                              : " border-transparent"
                          } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                        >
                          {(type !== "add"
                            ? parseInt(
                                directoryState.editUpliftData?.min_credit_score
                              ) >
                              parseInt(
                                directoryState.editUpliftData?.max_credit_score
                              )
                            : parseInt(
                                directoryState.addUpliftData?.min_credit_score
                              ) >
                              parseInt(
                                directoryState.addUpliftData?.max_credit_score
                              )) && <TooltipCustom />}
                          <div className="mx-1 opacity-70">Max</div>
                          <input
                            type="number"
                            placeholder="Enter"
                            className={`bg-[#f2f2f2] ${
                              (type !== "add"
                                ? parseInt(
                                    directoryState.editUpliftData
                                      ?.min_credit_score
                                  ) >
                                  parseInt(
                                    directoryState.editUpliftData
                                      ?.max_credit_score
                                  )
                                : parseInt(
                                    directoryState.addUpliftData
                                      ?.min_credit_score
                                  ) >
                                  parseInt(
                                    directoryState.addUpliftData
                                      ?.max_credit_score
                                  )) && "text-[#f74343]"
                            } font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                            value={
                              type === "add"
                                ? directoryState.addUpliftData
                                    ?.max_credit_score || ""
                                : directoryState.editUpliftData
                                    ?.max_credit_score || ""
                            }
                            onChange={(e) =>
                              dispatch(
                                type === "add"
                                  ? updateAddUpliftData({
                                      max_credit_score:
                                        e.target.value,
                                    })
                                  : updateEditUpliftData({
                                      max_credit_score:
                                        e.target.value,
                                    })
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-2 mx-2 opacity-90">
                        Property Ownership Status
                      </div>
                      <YesNoSwitch
                        name="property_ownership_status"
                        type={type}
                      />
                    </div>
                    <div
                      className={`flex flex-col ${!isSmallScreen && "ml-5"}`}
                    >
                      <div className="mx-2 opacity-90">
                        Brokerage Loading Treatment
                      </div>
                      <div className="flex flex-row items-center p-3 mx-2 w-[95%] rounded-md bg-[#f2f2f2]">
                        <div className="opacity-70">%</div>
                        <input
                          type="number"
                          placeholder="Enter"
                          className="bg-[#f2f2f2] font-bold w-full mx-2 border-0 p-0 placeholder:font-normal"
                          value={
                            type === "add"
                              ? directoryState.addUpliftData
                                  ?.brokerage_loading_treatment || ""
                              : directoryState.editUpliftData
                                  ?.brokerage_loading_treatment || ""
                          }
                          onChange={(e) =>
                            dispatch(
                              type === "add"
                                ? updateAddUpliftData({
                                    brokerage_loading_treatment:
                                      e.target.value === ""
                                        ? 0
                                        : e.target.value,
                                  })
                                : updateEditUpliftData({
                                    brokerage_loading_treatment:
                                      e.target.value === ""
                                        ? 0
                                        : e.target.value,
                                  })
                            )
                          }
                        />
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">ABN age</div>
                        <div className="flex flex-row">
                          <div
                            className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.min_abn_age ||
                                    ""
                                  : directoryState.editUpliftData
                                      ?.min_abn_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_abn_age: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_abn_age: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_abn_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_abn_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_abn_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_abn_age
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData?.min_abn_age
                                ) >
                                parseInt(
                                  directoryState.editUpliftData?.max_abn_age
                                )
                              : parseInt(
                                  directoryState.addUpliftData?.min_abn_age
                                ) >
                                parseInt(
                                  directoryState.addUpliftData?.max_abn_age
                                )) && <TooltipCustom />}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_abn_age
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_abn_age
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_abn_age
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_abn_age
                                    )) && "text-[#f74343]"
                              } font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.max_abn_age ||
                                    ""
                                  : directoryState.editUpliftData
                                      ?.max_abn_age || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_abn_age: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_abn_age: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">Term</div>
                        <div className="flex flex-row">
                          <div
                            className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.min_term || ""
                                  : directoryState.editUpliftData?.min_term ||
                                    ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_term: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_term: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_term
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_term
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_term
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_term
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData?.min_term
                                ) >
                                parseInt(
                                  directoryState.editUpliftData?.max_term
                                )
                              : parseInt(
                                  directoryState.addUpliftData?.min_term
                                ) >
                                parseInt(
                                  directoryState.addUpliftData?.max_term
                                )) && <TooltipCustom />}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] font-bold ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData?.min_term
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData?.max_term
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData?.min_term
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData?.max_term
                                    )) && "text-[#f74343]"
                              } w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData?.max_term || ""
                                  : directoryState.editUpliftData?.max_term ||
                                    ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_term: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_term: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mx-2 mt-3 opacity-90">
                          Asset Age + Term
                        </div>
                        <div className="flex flex-row">
                          <div
                            className={`border-transparent flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            <div className="mx-1 opacity-70">Min</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className="bg-[#f2f2f2] font-bold w-12 mx-2 border-0 p-0 placeholder:font-normal"
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.min_asset_age_plus_term || ""
                                  : directoryState.editUpliftData
                                      ?.min_asset_age_plus_term || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        min_asset_age_plus_term: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        min_asset_age_plus_term: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                          <div
                            className={`${
                              (
                                type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_age_plus_term
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_age_plus_term
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_age_plus_term
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_age_plus_term
                                    )
                              )
                                ? "border-[#f74343]"
                                : " border-transparent"
                            } flex relative bg-[#f2f2f2] flex-row border-[3px] rounded-md items-center p-3 mx-2 w-full`}
                          >
                            {(type !== "add"
                              ? parseInt(
                                  directoryState.editUpliftData
                                    ?.min_asset_age_plus_term
                                ) >
                                parseInt(
                                  directoryState.editUpliftData
                                    ?.max_asset_age_plus_term
                                )
                              : parseInt(
                                  directoryState.addUpliftData
                                    ?.min_asset_age_plus_term
                                ) >
                                parseInt(
                                  directoryState.addUpliftData
                                    ?.max_asset_age_plus_term
                                )) && <TooltipCustom />}
                            <div className="mx-1 opacity-70">Max</div>
                            <div className="opacity-70">M</div>
                            <input
                              type="number"
                              placeholder="Enter"
                              className={`bg-[#f2f2f2] font-bold ${
                                (type !== "add"
                                  ? parseInt(
                                      directoryState.editUpliftData
                                        ?.min_asset_age_plus_term
                                    ) >
                                    parseInt(
                                      directoryState.editUpliftData
                                        ?.max_asset_age_plus_term
                                    )
                                  : parseInt(
                                      directoryState.addUpliftData
                                        ?.min_asset_age_plus_term
                                    ) >
                                    parseInt(
                                      directoryState.addUpliftData
                                        ?.max_asset_age_plus_term
                                    )) && "text-[#f74343]"
                              } w-12 mx-2 border-0 p-0 placeholder:font-normal`}
                              value={
                                type === "add"
                                  ? directoryState.addUpliftData
                                      ?.max_asset_age_plus_term || ""
                                  : directoryState.editUpliftData
                                      ?.max_asset_age_plus_term || ""
                              }
                              onChange={(e) =>
                                dispatch(
                                  type === "add"
                                    ? updateAddUpliftData({
                                        max_asset_age_plus_term: e.target.value,
                                      })
                                    : updateEditUpliftData({
                                        max_asset_age_plus_term: e.target.value,
                                      })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 mx-2 opacity-90">Sale Hire Back</div>
                      <YesNoSwitch name="sale_hire_back" type={type} />
                      <div className="mt-2 mx-2 opacity-90">Private Sale</div>
                      <YesNoSwitch name="private_sale" type={type} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditUpliftModal;
